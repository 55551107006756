import countBy from 'lodash/countBy';

import GET_CART_ITEMS_BY_USER_ID_QUERY from '@/graphql/queries/GetCartItemsByUserId';
import CREATE_CART_ITEMS_MUTATION from '@/graphql/mutations/CreateCartItems';
import DELETE_CART_ITEMS_MUTATION from '@/graphql/mutations/DeleteCartItems';
import GET_PRICES_QUERY from '@/graphql/queries/getPrices';
import CARDLESS_CHECKOUT_MUTATION from '@/graphql/mutations/CardlessCheckout';

const isSubscriptionComboProduct = (code) => ['WILL', 'POA'].includes(code);

function checkIfProductRequiresConfirmation(rootState, getters, code) {
  const dependentProductCodes = getters.productDependencies(code);
  const isDependentProductsInCart = dependentProductCodes.some(
    (dependentProductCode) => getters.isInCart(dependentProductCode)
  );

  const productsWithConfirmation = ['WILL', 'LEGAL_ADVICE_BOOKING'];
  const isProductNeedConfirmation = productsWithConfirmation.includes(code);

  return (
    isDependentProductsInCart ||
    (!isDependentProductsInCart && isProductNeedConfirmation)
  );
}

function getRemovalCartItemIdsFromCodes(cartItems, codes) {
  return Object.entries(countBy(codes)).flatMap(([code, count]) => {
    return cartItems
      .filter((item) => code === item.product.code)
      .map((item) => item.id)
      .slice(0, count);
  });
}

function showCartSnackbar(codes, i18n) {
  window.$nuxt.$emit('snackbar', {
    icon: 'success',
    type: 'success',
    placement: 'top-right',
    relative: 'cart',
    text: i18n.t('cart.itemAdded', {
      productName: i18n.t(`cart.products.${codes[0]}.title`),
    }),
  });
}

export default {
  setSubscriptionAutoRenewal({ commit }, subscriptionAutoRenewal) {
    commit('setSubscriptionAutoRenewal', subscriptionAutoRenewal);
  },
  async setDiscountCode({ commit, dispatch }, discountCode) {
    commit('setDiscountCode', discountCode);
    await dispatch('getCartItems');
  },
  async getCartItems({ rootGetters, getters, commit }) {
    if (!rootGetters.userId) {
      commit('setCartItemsWithPriceData', {
        cartItems: [],
        cartPriceData: {},
      });
      return [];
    }

    const { data: cartData } =
      await this.app.apolloProvider.defaultClient.query({
        fetchPolicy: 'no-cache',
        query: GET_CART_ITEMS_BY_USER_ID_QUERY,
        variables: {
          userId: rootGetters.userId,
        },
      });

    const cartItems = cartData?.getCartItemsByUserId ?? [];
    const discountCode =
      getters.discountCode || rootGetters['coupon/couponLatestReferral'];

    const { data: priceData } =
      await this.app.apolloProvider.defaultClient.query({
        fetchPolicy: 'no-cache',
        query: GET_PRICES_QUERY,
        variables: {
          products: cartItems.map((item) => item.product.code),
          discountCode,
        },
      });
    commit('setCartItemsWithPriceData', {
      cartItems,
      cartPriceData: priceData?.getPrices ?? {},
    });
    return cartItems;
  },
  async addToCart(
    { rootGetters, getters, dispatch },
    { codes = [], showSnackbar = true }
  ) {
    const codesToAdd = codes.filter((code) => getters.canAddToCart(code));
    if (
      codesToAdd.some(isSubscriptionComboProduct) &&
      !codesToAdd.includes('SUBSCRIPTION') &&
      getters.canAddToCart('SUBSCRIPTION')
    ) {
      codesToAdd.push('SUBSCRIPTION');
    }
    if (codesToAdd.length === 0 || !rootGetters.userId) {
      return [];
    }

    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: CREATE_CART_ITEMS_MUTATION,
      variables: {
        userId: rootGetters.userId,
        products: codesToAdd,
      },
    });

    window.$nuxt.$emit('sendTrackingEvent', {
      event: 'add_to_cart',
      props: {
        items: data.createCartItems.map((cartItem) => ({
          item_id: cartItem.product.code,
          product_price_slug: rootGetters['product-prices/slugOf'](
            cartItem.product.code
          ),
        })),
        ...data.createCartItems.reduce((acc, cartItem) => {
          acc[`added_${cartItem.product.code.toLowerCase()}_to_cart`] = true;
          return acc;
        }, {}),
      },
    });

    if (showSnackbar) {
      showCartSnackbar(codesToAdd, this.app.i18n);
    }
    await dispatch('getCartItems');
    return data?.createCartItems;
  },
  async removeFromCart({ rootState, rootGetters, getters, dispatch }, code) {
    const removeCartItem = async (codes) => {
      const { data } = await this.app.apolloProvider.defaultClient.mutate({
        mutation: DELETE_CART_ITEMS_MUTATION,
        variables: {
          cartItemIds: getRemovalCartItemIdsFromCodes(getters.cartItems, codes),
        },
      });

      const filteredCodes = codes.filter((code) =>
        getters.cartItemCodes.includes(code)
      );
      window.$nuxt.$emit('sendTrackingEvent', {
        event: 'remove_from_cart',
        props: {
          items: filteredCodes.map((code) => ({
            item_id: code,
            product_price_slug: rootGetters['product-prices/slugOf'](code),
          })),
          ...filteredCodes.reduce((acc, code) => {
            acc[`removed_${code.toLowerCase()}_from_cart`] = true;
            return acc;
          }, {}),
        },
      });
      await dispatch('getCartItems');
      return data.deleteCartItems;
    };
    const isProductInCart = getters.isInCart(code);
    const shouldShowRemovalModal = checkIfProductRequiresConfirmation(
      rootState,
      getters,
      code
    );

    if (isProductInCart && shouldShowRemovalModal) {
      await dispatch('setProductRemoval', {
        code,
        callback: async (code) => {
          await removeCartItem([code, ...getters.productDependencies(code)]);
        },
      });
    } else if (isProductInCart) {
      await removeCartItem([code]);
    }
  },
  setProductRemoval({ commit }, { code, callback }) {
    commit('setProductRemoval', {
      code,
      callback,
    });
  },
  async cardlessPayment(
    { rootGetters, getters },
    { productsWithCustomPricesToken }
  ) {
    const {
      data: {
        cardlessCheckout: { success, message },
      },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: CARDLESS_CHECKOUT_MUTATION,
      variables: {
        userId: rootGetters.userId,
        products:
          productsWithCustomPricesToken === null ? getters.cartItemCodes : [],
        expectedCostInCents: getters.cartFinalPrice,
        discountCode: getters.discountCode,
        productsWithCustomPricesToken,
      },
    });

    if (!success) {
      throw new Error(message);
    }
  },
};
