export default () => ({
  slug: 'sw',
  branding: {
    logo: null,
    theme: null,
    darker: null,
    lighter: null,
    landingImage: null,
  },
  features: {
    dashboardEnabled: true,
    willEnabled: true,
    powerOfAttorneyEnabled: true,
    endOfLifeEnabled: true,
    giftEnabled: true,
    checkoutLegalAdviceEnabled: true,
    onboardingNeedsAssessmentEnabled: true,
    affiliateUserIdEnabled: false,
    couponCodeEnabled: false,
  },
  getStartedBranding: {
    title: null,
    description: null,
    affiliateLogoVisible: false,
  },
  appBranding: {
    affiliateLogoVisible: false,
  },
});
