export default () => ({
  subscriptionAutoRenewal: true,
  discountCode: null,
  cartItems: [],
  cartPriceData: {},
  productRemoval: {
    code: null,
    callback: null,
  },
});
