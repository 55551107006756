import GET_TOOLS from '@/graphql/queries/GetTools';
import CREATE_TOOL from '@/graphql/mutations/CreateTool';
import UPDATE_TOOL from '@/graphql/mutations/UpdateTool';
import UPSERT_VAULT_ITEMS_BY_TOOL from '@/graphql/mutations/UpsertVaultItemsByTool';

export default {
  async getTools({ commit }, { ownerId }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_TOOLS,
      fetchPolicy: 'no-cache',
      variables: {
        ownerId,
      },
    });
    commit('setTools', data.tools);
    return data.tools;
  },
  async createToolIfNotExists({ dispatch, state }, { ownerId, type }) {
    const tool = state.tools.find((t) => t.type === type);
    if (!tool) {
      await dispatch('createTool', { ownerId, type });
    }
  },
  async createTool({ commit }, { ownerId, type }) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: CREATE_TOOL,
      variables: {
        input: {
          ownerId,
          type,
        },
      },
    });
    const newTool = data.createTool;
    commit('addTool', newTool);
  },
  async updateTool({ commit }, { id, status }) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: UPDATE_TOOL,
      variables: {
        input: {
          id,
          status,
        },
      },
    });
    const updatedTool = data.updateTool;
    commit('updateTool', updatedTool);
  },

  async upsertVaultItemsByTool(
    { commit },
    { toolId, vaultItems, deletedVaultItemIds }
  ) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: UPSERT_VAULT_ITEMS_BY_TOOL,
      variables: {
        input: {
          toolId,
          vaultItems,
          deletedVaultItemIds,
        },
      },
    });
    const updatedTool = data.upsertVaultItemsByTool;
    commit('updateTool', updatedTool);
    return updatedTool;
  },
};
