import GET_ORDERS from '@/graphql/queries/GetOrders';

export default {
  async getOrders({ rootGetters, commit }) {
    const userId = rootGetters.userId;
    if (!userId) {
      return;
    }
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_ORDERS,
      variables: {
        userId,
      },
    });
    commit('setOrders', data?.getOrders ?? []);
  },
};
