import GET_PRODUCT_PRICES_QUERY from '@/graphql/queries/getProductPrices';

const PRODUCT_CODES = [
  'WILL',
  'PARTNER_WILL',
  'POA',
  'PARTNER_POA',
  'RECENT_UNLOCK',
  'UNLOCK',
  'SUBSCRIPTION',
  'SUBSCRIPTION_RENEWAL',
  'LEGAL_ADVICE',
  'LEGAL_ADVICE_BOOKING',
  'WILL_GIFT',
  'POA_GIFT',
];

export default {
  async getProductPrices(
    { rootState, rootGetters, commit },
    productCodes = PRODUCT_CODES
  ) {
    const {
      data: { getProductPrices },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_PRODUCT_PRICES_QUERY,
      variables: {
        userId: rootState.userId,
        productCodes: productCodes || PRODUCT_CODES,
        discountCode:
          rootGetters['cart/discountCode'] ||
          rootGetters['coupon/couponLatestReferral'],
      },
    });
    commit('setProductPrices', getProductPrices);
  },
};
