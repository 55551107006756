import cookies from 'js-cookie';

export default {
  setSlug({ commit }, value) {
    commit('setSlug', value);
  },
  setBranding({ commit }, value) {
    commit('setBranding', value);
  },
  setFeatures({ commit }, value) {
    commit('setFeatures', value);
  },
  setGetStartedBranding({ commit }, value) {
    commit('setGetStartedBranding', value);
  },
  setAppBranding({ commit }, value) {
    commit('setAppBranding', value);
  },
  resetAffiliate({ commit }) {
    commit('setSlug', 'sw');
    commit('setBranding', {
      logo: null,
      theme: '#00D9BF',
      darker: '#00A894',
      lighter: '#D2F9E2',
      landingImage: null,
    });
    commit('setFeatures', {
      dashboardEnabled: true,
      willEnabled: true,
      powerOfAttorneyEnabled: true,
      endOfLifeEnabled: true,
      giftEnabled: true,
      checkoutLegalAdviceEnabled: true,
      onboardingNeedsAssessmentEnabled: true,
    });
    commit('setGetStartedBranding', {
      title: null,
      description: null,
      affiliateLogoVisible: false,
    });
    commit('setAppBranding', {
      affiliateLogoVisible: false,
    });
    cookies.remove('affiliate_slug');
  },
};
