import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';

export default {
  async getCouponLatestReferral({ commit }) {
    const {
      data: {
        currentUser: { user },
      },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: CURRENT_USER_QUERY,
    });
    commit('setCouponLatestReferral', user.latest_referral_coupon);
  },
  setCouponLatestReferral({ commit }, coupon) {
    commit('setCouponLatestReferral', coupon);
  },
};
